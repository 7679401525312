import PagedFilterBase from './PagedFilterBase'
import EmailScheduledStatus from '../models/api/admin/emails/scheduled/EmailScheduledStatus'

class EmailScheduledFilter extends PagedFilterBase {

    Status: EmailScheduledStatus = EmailScheduledStatus.Scheduled
    MonthsPrior = 1

    constructor(init?: Partial<EmailScheduledFilter>) {
        super()

        if (init) Object.assign(this, init);
    }
}

export default EmailScheduledFilter


import { PagedResultSet } from 'tradingmate-modules/src/models/api/common'
import { Component, Vue } from 'vue-property-decorator'
import { Services } from 'tradingmate-modules/src/services'
import EmailScheduledModel from 'tradingmate-modules/src/models/api/admin/emails/scheduled/EmailScheduledModel'
import EmailScheduledFilter from 'tradingmate-modules/src/filters/EmailScheduledFilter'
import Paging from '@/components/actions/Paging.vue'
import { Table, TableData, TableHeader, TableRow } from '@/components/table'
import Utils from 'tradingmate-modules/src/Utils'
import InfoState from '@/components/status/InfoState.vue'
import EmailScheduledStatus from 'tradingmate-modules/src/models/api/admin/emails/scheduled/EmailScheduledStatus'
import EmailScheduledUpdateDto
  from '../../../../../../tradingmate-modules/src/models/api/admin/emails/scheduled/EmailScheduledUpdateDto'

@Component({
  components: {
    Table,
    TableHeader,
    TableRow,
    TableData,
    Paging,
    InfoState
  }
})

export default class ScheduledEmailsIndex extends Vue {
  private scheduledEmails: PagedResultSet<EmailScheduledModel> | null = null
  private filter = new EmailScheduledFilter({ Status: EmailScheduledStatus.Scheduled, MonthsPrior: 1 })
  private loading = false
  private debounce: number | undefined = undefined
  private isoToDisplayDate = Utils.isoToDisplayDate

  mounted (): void {
    this.getScheduledEmails()
  }

  handlePaged (page: number): void {
    this.filter.Page = page
    this.getScheduledEmails()
  }

  getScheduledEmails (): void {
    this.loading = true
    Services.API.Admin.Emails.GetScheduledEmails(this.filter)
      .then((returnData) => { this.scheduledEmails = returnData })
      .finally(() => { this.loading = false })
  }

  private sending = ''
  onSendNow (email: EmailScheduledModel): void {
    this.sending = email.EmailScheduledId
    Services.API.Admin.Emails.EmailScheduledSend(email.EmailScheduledId)
      .then((result) => {
        if (!result) return
        if (!this.scheduledEmails) return
        const idx = this.scheduledEmails.Items.findIndex(x => x.EmailScheduledId === email.EmailScheduledId)
        this.scheduledEmails.Items.splice(idx, 1)
      })
      .finally(() => {
        this.sending = ''
      })
  }

  private cancelling = ''
  onCancel (email: EmailScheduledModel): void {
    this.cancelling = email.EmailScheduledId
    const dto: EmailScheduledUpdateDto = { Status: EmailScheduledStatus.Cancelled }
    Services.API.Admin.Emails.EmailScheduledUpdate(email.EmailScheduledId, dto)
      .then((result) => {
        if (!result) return
        if (!this.scheduledEmails) return
        const idx = this.scheduledEmails.Items.findIndex(x => x.EmailScheduledId === email.EmailScheduledId)
        this.scheduledEmails.Items.splice(idx, 1)
      })
      .finally(() => {
        this.cancelling = ''
      })
  }
}

